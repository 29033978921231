
import { Component, ViewEncapsulation } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-no-connection',
  templateUrl: './no-connection.component.html',
  styleUrls: ['./no-connection.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class NoConnectionComponent {

  constructor(private router: Router) { }

  clickNavigateButton() {
    return this.router.navigate(['library']);
  }

}
