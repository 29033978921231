import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import { FidjiPurchaseManagerService, IMPurchaseState } from '@immanens-com/fidji-api-module-2';
import { NavigationServicesService } from '../../services/navigation-services.service';

/**
 * Top bar shared by other components
 */

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation : ViewEncapsulation.None // otherwise it seems we have conflicts with bootstrap
})
export class HeaderComponent {

  @Input() title = '';

  @Input() showTitle = true;

  @Input() imageUrl: SafeUrl = 'assets/images/logo_delimobi.svg';

  @Input() backButtonText;

  @Input() showBackButton = false;

  @Input() showDeleteButton = false;

  @Input() backURL: string;

  @Output() clickDeleteButton = new EventEmitter();

  inPurchasing = false;

  constructor(
    private purchaseService: FidjiPurchaseManagerService,
    private navigationService: NavigationServicesService) {

      this.purchaseService.currentPurchaseStatus$.subscribe((globalState) => {
        this.inPurchasing = globalState !== IMPurchaseState.None;
      });
    }

  /**
   * Return to the previous route
   */
  clickGoBack() {
    this.navigationService.popToUrl(this.backURL);
  }
}
