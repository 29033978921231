import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderComponent} from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {NavigationBarComponent} from './components/navigation-bar/navigation-bar.component';
import {PurchaseCouponFormComponent} from './components/formulars/purchase-coupon/purchase-coupon-form.component';
import {NotificationComponent} from './components/notification/notification.component';
import {TranslateModule} from '@ngx-translate/core';
import {I18nDatePipe} from './pipes/i18nDate.pipe';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgCircleProgressModule.forRoot(),
    TranslateModule.forChild()
  ],
  declarations: [
    NavigationBarComponent,
    HeaderComponent,
    PurchaseCouponFormComponent,
    NotificationComponent,
    I18nDatePipe
  ],
  exports: [
    NavigationBarComponent,
    HeaderComponent,
    PurchaseCouponFormComponent,
    NotificationComponent,
    I18nDatePipe,
    TranslateModule,
    HttpClientModule
  ]
})
export class SharedModule {}
