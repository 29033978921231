// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  enableServiceWorking: true,
  language: 'fr',
  device: 'web',
  fixedDeviceSettings: {
      device_description: "window.navigator.userAgent",
      device_os: "window.navigator.appVersion",
      // tslint:disable-next-line:max-line-length
      // device_token: '5fff7a7cc24d6c8a9d99021a9396ac1a32101efc4822651565bd85906b5399fec5eba63f98a9adc0cda8102bc0964cc194279cc3f4e2fee76442ac20507bfcd35cac8216b5107f1dfbbb184ee9124f10bc32da47948844a9bbffcf705950ecc60cbe9f91269f98b1e487607888ce995dae2c5ac224c906c19bea5a41e9019eb902e51a84d7ba73ddb0b7cf2c1e2b26f8',
      device_token: '30658ece72aa56a01b3859f0f52acb2c2d38f0977610a06c292ab29f656502256cd6266ac998718a00cbe3862379cd09e4bbb131a97703e1959b4cfd8df82759bafa6c79d06820d701a46e8b98977392b5ea83efb7a8311255d8ff8eef73f4180c67bb68ba39ca9403a70ac31566137998ec4be2f388ed35e613cb3d2430134b6134192d5a1dc731e6107d6885b422b7fdf591d0fcd39681460f500685175762',

      app_id: 298,
      api_url: 'https://phenix2.immanens.com/api/v1/app/',
      api_key: 'b7d4-ff77-fcc4-89b6',

      app_version: 'no device',

      force_device_app: true,
      angular_version: '1.1.2 (copie from package.json)'
    }
};
