import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {
  FidjiLibraryManager,
  FidjiNotificationManagerService,
  FidjiPublicationWebService,
  IMNotification,
  IMPublication
} from '@immanens-com/fidji-api-module-2';
import {interval, merge, Observable, of, Subject, Subscription} from 'rxjs';
import {delay, distinctUntilChanged, filter, map, share, startWith, switchMap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

const NOTIFICATION_SHOWING_TIME = 5000;
const NOTIFICATION_ANIMATION_DURATION = 1000;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  show$: Observable<boolean>;

  $hide = new Subject<boolean>();

  notification$: Observable<IMNotification>;

  currentPublication$: Observable<IMPublication>;

  showReadIssueBtn$: Observable<boolean>;

  _sub: Subscription;

  constructor(private notificationManager: FidjiNotificationManagerService,
              private libraryManager: FidjiLibraryManager,
              private publicationWS: FidjiPublicationWebService) {

    this.notification$ = this.notificationManager.notification$.pipe(
      filter(n => n !== undefined)
    );

    this.currentPublication$ = this.notification$
      .pipe(
        map((notification) => notification && notification.payload && notification.payload.id ? notification.payload.id : null),
        switchMap((id) => {
          if (id) {
            return this.publicationWS.getIssue(id, {}).pipe(
              // Send null while we have no response (prevent read button to show with not updated publication)
              startWith(null)
            );
          } else {
            return of(null);
          }
        }),
        share()
      );

    this.showReadIssueBtn$ = this.currentPublication$
      .pipe(
        map((publication) => publication != null)
      );

    const hasNotification$ = this.notification$.pipe(
      switchMap((notification) => {
        if (notification != null) {
          return of(false).pipe(
            delay(NOTIFICATION_SHOWING_TIME),
            startWith(true)
          );
        } else {
          return of(false);
        }
      }),
    );

    this.show$ = merge(hasNotification$, this.$hide).pipe(
      distinctUntilChanged()
    );

  }

  ngOnInit() {

    this._sub = interval(NOTIFICATION_SHOWING_TIME + NOTIFICATION_ANIMATION_DURATION)
      .subscribe(
        () => {
          this.notificationManager.pop();
        }
      );

  }

  ngOnDestroy() {
    if (this._sub != null) {
      this._sub.unsubscribe();
    }
  }


  async readIssue() {
    try {
      const publication = await this.currentPublication$.toPromise();
      if (publication) {
        this.libraryManager.read(publication.issues_data[0]);
      }
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        if (e.status === 404) {
          throw Error('No issue with this id from the web service');
        } else {
          throw Error('There is an error with this issue');
        }
      }
    }
  }

  hideNotification() {
    this.$hide.next();
  }

}
