import {Pipe} from '@angular/core';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

/**
 * This pipe is applied on every dates used.
 * It automatically send the current language to the date pipe, in order
 * to prevent the translate service to change the current application language
 */
@Pipe({
  name: 'i18nDate',
})
export class I18nDatePipe extends DatePipe {
  constructor(translateService: TranslateService) {
    super(translateService.currentLang);
  }
}
