import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules, NoPreloading} from '@angular/router';
import {NotFoundPageComponent} from './errors/not-found-page/not-found-page.component';
import {ErrorPageComponent} from './errors/error-page/error-page.component';
import {LoginGuardService} from './account/services/login.guard';
import { NoConnectionComponent } from './errors/no-connection/no-connection.component';
import {ConnectionGuard} from './errors/guards/connection.guard';

const routes: Routes = [
  { path: '', redirectTo: '/home/grid', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: './home/home.module#HomeModule',
    canActivateChild: [ConnectionGuard],
    data: {depth: 1}
  },
  {
    path: 'detail',
    loadChildren: './detail/detail.module#DetailModule',
    canActivateChild: [ConnectionGuard],
    data: {depth: 2}
  },
  { path: 'account', loadChildren: './account/account.module#AccountModule', data: {depth: 1}, canActivateChild: [ConnectionGuard] },
  {
    path: 'search',
    loadChildren: './search/search.module#SearchModule',
    canActivateChild: [ConnectionGuard]
  },
  {
    path: 'library',
    loadChildren: './library/library.module#LibraryModule',
  },
  { path: 'no-connection', component: NoConnectionComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: 'not-found', component: NotFoundPageComponent },
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
  /*providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    }
  ]*/
})
export class AppRoutingModule { }
