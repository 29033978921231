import { Injectable, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, UrlSegment, ActivatedRoute } from '@angular/router';
import { FidjiNativeActions } from '@immanens-com/fidji-api-module-2';


export enum NavigationActionType {
  PUSH = 'push',
  POP = 'pop',
  PREPARE = 'prepare',
  CANCEL = 'cancel',
  NONE = 'none'
}

interface NavigationAction {
  action: NavigationActionType;
  duration?: number;
  delay?: number;
}



@Injectable({
  providedIn: 'root'
})
export class NavigationServicesService {

  currentNavigationAction: NavigationAction = null;

  constructor(
    private location: Location,
    protected route: ActivatedRoute,
    private router: Router) {

      this.location.subscribe(x => {
        console.log('location.subscribe : ');
        console.log('pop : ' + x.pop);
        console.log('state : ' + x.state);
        console.log('type : ' + x.type);
        console.log('url : ' + x.url);
        if (x.pop) {
          FidjiNativeActions.Generic.nativeActionWithData('hybribAnimation', this.currentNavigationAction);
          this.currentNavigationAction = null;
        }
      });
      this.router.events.subscribe((e: RouterEvent) => {

        if (!this.currentNavigationAction || this.currentNavigationAction.action === NavigationActionType.NONE) {
          return;
        }

        if (e instanceof NavigationStart) {
          FidjiNativeActions.Generic.nativeActionWithData('hybribAnimation', {'action': NavigationActionType.PREPARE});
        }
        if (e instanceof NavigationEnd) {
          FidjiNativeActions.Generic.nativeActionWithData('hybribAnimation', this.currentNavigationAction);
          this.currentNavigationAction = null;
        }
        if (e instanceof NavigationCancel || e instanceof NavigationError) {
          FidjiNativeActions.Generic.nativeActionWithData('hybribAnimation', {'action': NavigationActionType.CANCEL});
          this.currentNavigationAction = null;
        }
      });
    }

    goToUrl(url: string) {
      this.currentNavigationAction = null;
      this.router.navigateByUrl(url);
    }

    goToSnapshot() {
      this.router.navigate(this.route.snapshot.url);
    }

    pushToUrl(url: string) {
      console.log('this.route.snapshot.url : ' + this.route.snapshot.url);
      this.goTo(url, NavigationActionType.PUSH);
    }

    popToUrl(url?: string) {
      if (url) {
        this.router.navigateByUrl(url);
      } else {
        this.currentNavigationAction = {action: NavigationActionType.POP};
        FidjiNativeActions.Generic.nativeActionWithData('hybribAnimation', {'action': NavigationActionType.PREPARE});
        this.location.back();
      }
    }

    private goTo(url: string, action: NavigationActionType ) {
      this.currentNavigationAction = {action: action};
      this.router.navigateByUrl(url);
    }
}
