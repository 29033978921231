/**
 * List of functions helping developer to build urls of the application.
 * Each time you add an url to the router, please make the corresponding helper
 */
import {IMIssue, IMPublication} from '@immanens-com/fidji-api-module-2';

export const ApplicationRoutes = {
  Home: () => '/home/grid',
  Search: () => '/search',
  Library: () => '/library',
  LibraryPublication: (publication_id) => `/library/publication/${publication_id}`,
  HomeGrid: () => '/home/grid',
  HomeShelf: () => '/home/shelf',
  DetailGroup: (group_id, edition, issue_id?) => issue_id ? `/detail/group/${group_id}/${edition}?issue_id=${issue_id}` : `/detail/group/${group_id}/${edition}`,
  DetailPublication: (publication_id) => `/detail/publication/${publication_id}`,
  DetailPublicationWithIssue: (publication_id, issue_id) => `/detail/publication/${publication_id}?issue_id=${issue_id}`,
  DetailCategory: (category_id) => `/detail/category/${category_id}`,
  Account: () => `/account`,
  Coupon: () => `/account/coupon`,
  Login: () => `/account/login`,
  CGU: () => '/account/cgu',
  Legal: () => '/account/legals',
};

export class Tools {
  static mySQLDateToTimestamp(mysqlDate: string): number {
    const stringHours = /\d{2}:\d{2}:\d{2}/.exec(mysqlDate);
    const stringDate = /\d{2}-\d{2}-\d{2}/.exec(mysqlDate);

    const date = new Date();

    if (stringHours && stringHours.length > 0) {
      const [hour, minute, second] = stringHours[0].split(':');
      date.setMinutes(+minute);
      date.setHours(+hour);
      date.setSeconds(+second);
    }

    if (stringDate && stringDate.length > 0) {
      const [year, month, day] = stringDate[0].split('-');
      date.setFullYear(+year + 2000);
      date.setMonth(+month - 1);
      date.setDate(+day);
    }

    return date.getTime();
  }


  static averageStateFor(issues: IMIssue[]) {
    if (issues == null) {
      return 0;
    } else {

      // Get the number of issues in download
      const issuesCount = issues.reduce((acc, i) => {
        if ('state' in i && i.state > 0 && i.state < 100) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);

      if (issuesCount === 0) {
        // If no issue in download, return maximum state
        return 200;
      }

      // Get the sum of all state in publication
      const stateCount = issues.reduce((acc, i) => {
        if ('state' in i && i.state < 100 && i.state > 0) {
          return acc + (i.state);
        } else {
          return acc;
        }
      }, 0);

      const pourcentage = (stateCount / (issuesCount * 100)) * 100;

      return +pourcentage.toFixed(0);
    }
  }

  static averageStateForPublications(publications: IMPublication[]) {
    if (publications && publications.length > 0) {

      const publicationsCount = publications.length;

      const publicationsAverages = publications.reduce((acc, p) => {
        return acc + Tools.averageStateFor(p.issues_data);
      }, 0);

      const pourcentage = (publicationsAverages / (publicationsCount * 100)) * 100;

      return +pourcentage.toFixed(0);
    } else {
      return 100;
    }
  }
}
