import { Injectable } from '@angular/core';
import {CanActivate, CanActivateChild, Router} from '@angular/router';
import {FidjiNetworkManagerService} from '@immanens-com/fidji-api-module-2';
import {map, pairwise, take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class ConnectionGuard implements CanActivate, CanActivateChild {

  constructor(private network: FidjiNetworkManagerService,
              private router: Router) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    // Here we just check state and return true immediatly
    // This is because we don't want to block the transition between two page in case of a slow network
    // See app.component.ts for more
    this.network.checkIsOnlineState();
    return this.network.isOnlineChange.pipe(
      pairwise(),
      take(1),
      map(([wasOnline, isOnline]) => isOnline),
    );
  }

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    this.network.checkIsOnlineState();
    return this.network.isOnlineChange.pipe(
      pairwise(),
      take(1),
      map(([wasOnline, isOnline]) => isOnline),
      tap((isOnline) => {
        if (!isOnline) {
          this.router.navigate(['/no-connection']);
        }
      }),
    );
  }

}
