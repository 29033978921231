import {AfterContentInit, Component, OnInit} from '@angular/core';
import {FidjiNotificationManagerService, FidjiStoreShopManagerService, FidjiNetworkManagerService} from '@immanens-com/fidji-api-module-2';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {FidjiUISlimLoadingBarService} from '@immanens-com/fidjiuimodule';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {Subscription} from 'rxjs';
import {SwUpdate} from '@angular/service-worker';
import {pairwise} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: []
})
export class AppComponent implements OnInit, AfterContentInit {

  private lastVisitedRoute: string;
  private networkSub: Subscription;
  private swSub: Subscription;

  constructor(private store: FidjiStoreShopManagerService,
              private networkService: FidjiNetworkManagerService,
              private progressBar: FidjiUISlimLoadingBarService,
              private notification: FidjiNotificationManagerService,
              private serviceWorker: SwUpdate,
              private router: Router,
              translate: TranslateService) {

    this.store.selectShopStore({id: 'default', label: ''});

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(environment.language);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(environment.language);

    this.subscribeToNetworkActivity();

    // Manage service worker if it's enable
    this.manageServiceWorker();
  }

  ngOnInit() {
    this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationStart) {
        this.progressBar.start();
      }
      if (e instanceof NavigationEnd) {
        this.progressBar.complete();
      }
      if (e instanceof NavigationCancel ||
        e instanceof NavigationError) {
        this.progressBar.reset();
      }
    });
  }

  ngAfterContentInit() {
    const rects = document.getElementsByTagName('rect');
    if (rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (rects[i].hasAttribute('clip-path') && rects[i].hasAttribute('style')) {
          rects[i].setAttribute('clip-path',
            rects[i].getAttribute('clip-path').replace('#', window.location.href + '#'));
          rects[i].setAttribute('style',
            rects[i].getAttribute('style').replace('#', window.location.href + '#'));
        }
      }
    }
  }

  /**
   * Subscribe to network isOnline observable
   * The connection is checked in certain page transition with Connection guard
   * If the network turn down, we just navigate to no-connection component
   * See connection.guard.ts for more
   */
  subscribeToNetworkActivity() {
    // Network isOnline observable does not complete, use a subscription
    this.networkSub = this.networkService.isOnlineChange.pipe(pairwise()).subscribe(([wasOnline, isOnline]) => {

      if (!isOnline && wasOnline) {
        // if not online and not on no-connection or library, go to no-connection
        this.lastVisitedRoute = this.router.url;

        this.router.navigate(['/no-connection']);
      } else if (isOnline && !wasOnline && new RegExp('no-connection').test(this.router.url)) {
        // if is online and on no-connection, go to last visited
        this.router.navigateByUrl(this.lastVisitedRoute);
      }
    });
  }

  /**
   * Manage service worker, if it is enable, check for update and reload if any.
   */
  manageServiceWorker() {
    if (this.serviceWorker.isEnabled) {

      this.swSub = this.serviceWorker.available.subscribe(() => {
        window.location.reload();
      });

      this.serviceWorker.checkForUpdate();
    }
  }
}



