import { Component, ViewEncapsulation } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {ApplicationRoutes, Tools} from '../../../utilities/helpers';
import {FidjiLibraryManager} from '@immanens-com/fidji-api-module-2';
import {distinctUntilChanged, filter, map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  encapsulation : ViewEncapsulation.None // conflict with bootstrap
})
export class NavigationBarComponent {

  public HomeURL = ApplicationRoutes.HomeGrid();
  public CategoryURL = ApplicationRoutes.HomeShelf();
  public SearchURL = ApplicationRoutes.Search();
  public LibraryURL = ApplicationRoutes.Library();
  public AccountURL = ApplicationRoutes.Account();

  public isDownloading$: Observable<number> = this.libraryManager.downloads$.pipe(
    map((list) => Tools.averageStateForPublications(list)),
    startWith(100),
    distinctUntilChanged()
  );

  public currentUrl$: Observable<string> = this.router.events.pipe(
    filter((e: RouterEvent) => e instanceof NavigationEnd),
    map((e) => e.url)
  )

  public urlContains(url: string, segments: string[]): boolean {
    return segments.reduce((acc, segment) => acc || new RegExp(segment).test(url), false);
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private libraryManager: FidjiLibraryManager) {
  }


}
