import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FidjiApiModule2} from '@immanens-com/fidji-api-module-2';
import {environment} from '../environments/environment';
import {HttpClient} from '@angular/common/http';
import {NotFoundPageComponent} from './errors/not-found-page/not-found-page.component';
import {ErrorPageComponent} from './errors/error-page/error-page.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {FidjiUISlimLoadingBarModule} from '@immanens-com/fidjiuimodule';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {ServiceWorkerModule} from '@angular/service-worker';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NoConnectionComponent } from './errors/no-connection/no-connection.component';
import {ConnectionGuard} from './errors/guards/connection.guard';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    ErrorPageComponent,
    NoConnectionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FidjiUISlimLoadingBarModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.enableServiceWorking }),
    FidjiApiModule2.forRoot(environment.fixedDeviceSettings)
  ],
  providers: [
    ConnectionGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

