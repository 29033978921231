import {Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import {FidjiCouponManagerService, FidjiNotificationManagerService, IMPushNotification} from '@immanens-com/fidji-api-module-2';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-purchase-coupon-form',
  templateUrl: './purchase-coupon-form.component.html',
  styleUrls: ['./purchase-coupon-form.component.scss'],
  encapsulation: ViewEncapsulation.None // otherwise it seems we have conflicts with bootstrap
})
export class PurchaseCouponFormComponent {

  error = false;
  $errorMessage = new BehaviorSubject('');
  purchasing = this.couponManager.purchasing$;

  @Output() done = new EventEmitter<boolean>();

  voucher = new FormControl('', [Validators.pattern(/^(\w{1,6}-)+(\w{1,6})$/)]);

  form = this.fb.group({
    voucher: this.voucher
  });

  // description : https://immredmine.immanens.com/issues/16869

  constructor(private couponManager: FidjiCouponManagerService,
              private notificationManager: FidjiNotificationManagerService,
              private translate: TranslateService,
              private fb: FormBuilder) { }

  voucherChange(input: any) {
    const currentValue: string = input.target.value;
    let tmpValue = currentValue.split('-').join('').toUpperCase();

    if (tmpValue.length > 0) {
      const groups = tmpValue.match(/.{1,4}/g);
      tmpValue = groups.join('-');
    }

    this.voucher.setValue(tmpValue);
  }

  async submitForm() {
    try {
      const voucher = this.voucher.value;
      const lisibleVoucher = voucher.split('-').join('');

      await this.couponManager.purchase(lisibleVoucher).toPromise();

      this.notificationManager.push(new IMPushNotification('Coupon', `Votre coupon ${voucher} à été correctement consumé`));
      this.notificationManager.pop();

      this.sendConsumedNotification(voucher);
      this.done.emit(true);

    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        if (e.status === 404 || e.status === 405) {
          this.translate.get('formular.coupon.not-found').subscribe(this.$errorMessage);
        } else if (e.status === 403) {
          this.translate.get('formular.coupon.already-use').subscribe(this.$errorMessage);
        } else {
          this.translate.get('formular.coupon.error').subscribe(this.$errorMessage);
        }
      } else {
        this.translate.get('formular.coupon.error').subscribe(this.$errorMessage);
      }
      this.error = true;
    }
    this.couponManager.purchasing$.next(false); // Correction en attendant la résolution de #17173
  }

  private sendConsumedNotification(voucher: string) {
    this.notificationManager.push(new IMPushNotification('Coupon', `Votre coupon ${voucher} à été correctement consumé`));
  }
}
